:root {
    --side-background: #000000;
    --side-text: #ffffff;
    --side-background-hover: #434343;
    --side-background-active: #FD7E14;
    --side-text-hover: #ffffff;
    --side-text-active: #000000;
}

@keyframes swing {

    0%,
    30%,
    50%,
    70%,
    100% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    60% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }
}

.pro-sidebar-layout::-webkit-scrollbar {
    display: none;
}

.pro-sidebar-layout {
    overflow: scroll;
    z-index: 999;
    padding-top: 60px;
    position: fixed !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 260px;
    min-width: 260px;
}

.pro-sidebar {
    color: var(--side-text);
    height: 100%;
    width: 260px;
    min-width: 260px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
}

.pro-sidebar>.pro-sidebar-inner {
    background: var(--side-background);
    height: 100%;
    position: relative;
    z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.3;
    left: 0;
    top: 0;
    z-index: 100;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 101;
    background-color: var(--side-background);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
    flex-grow: 1;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: none;
}

.pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

.pro-sidebar.rtl {
    text-align: right;
    direction: rtl;
}

@media (max-width: 480px) {
    .pro-sidebar.xs {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.xs.collapsed {
        left: -80px;
    }

    .pro-sidebar.xs.toggled {
        left: 0;
    }

    .pro-sidebar.xs.toggled .overlay {
        display: block;
    }

    .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px;
    }

    .pro-sidebar.xs.rtl.collapsed {
        left: auto;
        right: -80px;
    }

    .pro-sidebar.xs.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 576px) {
    .pro-sidebar.sm {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.sm.collapsed {
        left: -80px;
    }

    .pro-sidebar.sm.toggled {
        left: 0;
    }

    .pro-sidebar.sm.toggled .overlay {
        display: block;
    }

    .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px;
    }

    .pro-sidebar.sm.rtl.collapsed {
        left: auto;
        right: -80px;
    }

    .pro-sidebar.sm.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 768px) {
    .pro-sidebar.md {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.md.collapsed {
        left: -80px;
    }

    .pro-sidebar.md.toggled {
        left: 0;
    }

    .pro-sidebar.md.toggled .overlay {
        display: block;
    }

    .pro-sidebar.md.rtl {
        left: auto;
        right: -270px;
    }

    .pro-sidebar.md.rtl.collapsed {
        left: auto;
        right: -80px;
    }

    .pro-sidebar.md.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 992px) {
    .pro-sidebar.lg {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.lg.collapsed {
        left: -80px;
    }

    .pro-sidebar.lg.toggled {
        left: 0;
    }

    .pro-sidebar.lg.toggled .overlay {
        display: block;
    }

    .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px;
    }

    .pro-sidebar.lg.rtl.collapsed {
        left: auto;
        right: -80px;
    }

    .pro-sidebar.lg.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 1200px) {
    .pro-sidebar.xl {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.xl.collapsed {
        left: -80px;
    }

    .pro-sidebar.xl.toggled {
        left: 0;
    }

    .pro-sidebar.xl.toggled .overlay {
        display: block;
    }

    .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px;
    }

    .pro-sidebar.xl.rtl.collapsed {
        left: auto;
        right: -80px;
    }

    .pro-sidebar.xl.rtl.toggled {
        left: auto;
        right: 0;
    }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: '';
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 1px solid var(--side-background);
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    box-shadow: 1px 0px 0px var(--side-text), 0px -1px 0px var(--side-text), 0px 1px 0px var(--side-text), -1px 0px 0px var(--side-text);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar .pro-menu {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    position: relative;
    background-color: var(--side-background);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding-left: 50px !important;
    height: 51px;
}


.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: var(--side-text);
}

.pro-sidebar .pro-menu a:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
}

.pro-sidebar .pro-menu a:hover {
    color: var(--side-text-hover);
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item:hover {
    background-color: var(--side-background-hover);
    color: var(--side-text-hover);
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    background-color: var(--side-background-active);
    color: var(--side-text-active);
}

.pro-sidebar .pro-menu .pro-menu-item.active a {
    color: var(--side-text-active);
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
    display: flex;
    margin-right: 5px;
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 35px 8px 20px;
    cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
    outline: none;
    color: var(--side-text-hover);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 10px;
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    flex-grow: 1;
    flex-shrink: 1;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
    color: var(--side-text-hover);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item:before {
    background: var(--side-text);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    display: inline-block;
    border-style: solid;
    border-color: var(--side-text);
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(-45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item:before {
    background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    transform: rotate(45deg);
}



.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
    display: none;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    background-color: var(--side-background);
}

.pro-sidebar .pro-menu.square .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 0;
}

.pro-sidebar .pro-menu.round .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 4px;
}

.pro-sidebar .pro-menu.circle .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 50%;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.suffix-wrapper,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.prefix-wrapper {
    opacity: 0;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-list-item {
    background-color: var(--side-background);
    z-index: 111;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item {
    pointer-events: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    display: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    height: auto !important;
    position: fixed;
    visibility: hidden;
    min-width: 220px;
    max-width: 270px;
    background-color: transparent;
    max-height: 100%;
    padding-left: 3px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-left: 10px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: var(--side-background);
    padding-left: 20px;
    border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
    transition: visibility, transform 0.3s;
    visibility: visible;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
    animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
    margin-right: 0;
    margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 8px 20px 8px 35px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    right: auto;
    left: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    transform: rotate(135deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    margin-right: 0;
    margin-left: 15px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    padding-left: 0;
    padding-right: 3px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-right: 10px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 5px 8px 30px;
}

.popper-arrow {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
    right: 0;
    border-right: 7px solid var(--side-background);
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
    left: 0;
    border-left: 7px solid var(--side-background);
}

.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}

/*! react-sidenav v0.5.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-sidenav */
.sidenav---sidenav---_2tBP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1006;
    min-width: 64px;
    background: #db3d44;
    -webkit-transition: min-width 0.2s;
    -moz-transition: min-width 0.2s;
    -o-transition: min-width 0.2s;
    -ms-transition: min-width 0.2s;
    transition: min-width 0.2s;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
    display: none;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---sidenav-subnav---1EN61 {
    display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 240px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo+.sidenav---navtext---1AE_f {
    visibility: visible;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7>.sidenav---navitem---9uL5T {
    cursor: pointer;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    position: static;
    border: none;
    float: left;
    clear: both;
    width: 100%;
    background: transparent;
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    display: block;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
    display: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47 {
    position: relative;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47::before {
    content: " ";
    width: 100%;
    height: 28px;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:first-child {
    display: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:hover::before {
    background: #fff;
    opacity: 0.15;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
    filter: alpha(opacity=15);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47::before {
    content: " ";
    width: 100%;
    height: 28px;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T {
    color: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T::before {
    content: " ";
    width: 2px;
    height: 20px;
    left: 10px;
    top: 4px;
    position: absolute;
    border-left: 2px #fff solid;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:hover>.sidenav---navitem---9uL5T {
    background: transparent;
    color: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T {
    color: #f9dcdd;
    padding: 0 14px 0 20px;
    line-height: 28px;
}

.sidenav---sidenav-toggle---1KRjR {
    position: relative;
    float: left;
    width: 64px;
    height: 64px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
}

.sidenav---sidenav-toggle---1KRjR:focus {
    outline: none;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    display: block;
    width: 20px;
    height: 2px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 1px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    -ms-transition: all 0.15s;
    transition: all 0.15s;
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02+.sidenav---icon-bar---u1f02 {
    margin-top: 4px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    width: 25px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(2) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(3) {
    margin-top: -8px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sidenav---sidenav-nav---3tvij {
    float: left;
    padding: 0;
    margin: 0;
    clear: both;
    list-style: none;
    width: 100%;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ- {
    clear: both;
    position: relative;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T {
    cursor: default;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T::after {
    background: #fff;
    opacity: 0.15;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
    filter: alpha(opacity=15);
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::after {
    background: #000;
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after {
    background: #000;
    opacity: 0.25;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    filter: alpha(opacity=25);
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after {
    background: #000;
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    opacity: 1;
    -ms-filter: none;
    filter: none;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    color: #fff;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo>*,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f>*,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo>*,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f>* {
    color: #fff;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T {
    position: relative;
    display: block;
    line-height: 50px;
    height: 50px;
    white-space: nowrap;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T:focus {
    outline: 0;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    z-index: -1;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    color: #f9dcdd;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo>*,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f>* {
    color: #f9dcdd;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    display: block;
    float: left;
    width: 64px;
    height: 50px;
    margin-right: -6px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    line-height: 50px;
    text-align: center;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo+.sidenav---navtext---1AE_f {
    width: 0;
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: visibility 0s 0.2s, opacity 0.2s linear;
    -moz-transition: visibility 0s 0.2s, opacity 0.2s linear;
    -o-transition: visibility 0s 0.2s, opacity 0.2s linear;
    -ms-transition: visibility 0s 0.2s, opacity 0.2s linear;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.sidenav---sidenav-subnav---1EN61 {
    min-width: 200px;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    left: 64px;
    background: #fff;
    border: 1px #bbb solid;
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:first-child {
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px #ccc solid;
    font-size: 14px;
    font-weight: normal;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
    margin-top: 8px;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47:last-child {
    margin-bottom: 8px;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T {
    color: #db3d44;
    font-weight: bold;
    background: none;
    cursor: default;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T:hover {
    background: #fff;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T {
    display: block;
    text-decoration: none;
    color: #222;
    font-size: 13px;
    line-height: 30px;
    padding: 0 24px;
    cursor: pointer;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T:hover {
    background: #eee;
}

.sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T:focus {
    outline: 0;
}

.sidenav---sidenav---_2tBP {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 20px;
}

.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

/*# sourceMappingURL=react-sidenav.css.map*/