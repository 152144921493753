input,
select,
textarea,
.btn {
    min-height: 40px !important;
}

input,
select,
.btn {
    height: 40px !important;
}

.background {
    background-image: url(../images/background.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.background2 {
    background-image: url(../images/background2.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.background3 {
    background-image: url(../images/background3.png);
    background-repeat: no-repeat;
    background-size: cover;
}

/* .MuiSvgIcon-root {
    color: #ffffff !important
} */

.master {
    background-color: #ffffff;
    border: 3px #fd7e14 solid;
    border-radius: 16px;
    text-align: center;
    padding-top: 12px;
    cursor: pointer;
}

.master:hover {
    background-color: #ffe2ca;
}

.modal-dialog {
    display: flex;
    align-items: center;
    min-height: 90%;
    margin: 0 auto;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #fd7e14 !important;
    box-shadow: none;
}

.calendar>input,
.calendar>span,
.calendar>div,
.calendar>label,
.calendar>select {
    font-size: 0.8rem !important;
}

.status-booking-0 {
    background-color: #6c757d;
}

.status-booking-1 {
    background-color: #007AFF;
}

.status-booking-2 {
    background-color: #FFD60A;
}

.status-booking-3 {
    background-color: #FF9500;
}

.status-booking-4 {
    background-color: #17A100;
}

.status-booking-5 {
    background-color: #FF3B30;
}

.status-booking-6 {
    background-color: #FF45D6;
}

.status-booking-7 {
    background-color: #0dcaf0;
}


.status-rent-0 {
    background-color: #6c757d;
}

.status-rent-1 {
    background-color: #FF45D6;
}

.status-rent-2 {
    background-color: #0dcaf0;
}

.status-rent-3 {
    background-color: #5856D6;
}

.status-rent-4 {
    background-color: #FF3B30;
}

.status-rent-5 {
    background-color: #FF9F0A;
}

.status-rent-6 {
    background-color: #0A84FF;
}

.status-rent-7 {
    background-color: #FFCC00;
}

.status-rent-8 {
    background-color: #232F85;
}

.status-rent-9 {
    background-color: #37A6A6;
}

.status-rent-10 {
    background-color: #17A100;
}

.status-rent-11 {
    background-color: #FF3B30;
}

.status-rent-12 {
    background-color: #052c65;
}